<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家管理</li>
            <li class="breadcrumb-item active" aria-current="page">店家類型管理</li>
            <li class="breadcrumb-item active" aria-current="page">點數倍數管理</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto">
                <div class="table-responsive container-lg col-12">
                    <table class="table table-striped table-hover text-center align-middle">
                        <thead class="table-dark">
                            <tr>
                                <th class="col-3">店家類型</th>
                                <th class="col-3">店家類型（英文）</th>
                                <th class="col-3">點數倍數</th>
                                <th class="col-3">排序</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in store_type_data" :key="item">
                                <td>{{ item.name }}</td>
                                <td>
                                    <Field :name="('name_en_' + item.type_id)" v-slot="{ meta, field }" v-model="item.name_en" :rules="name_en_rules">
                                        <input
                                            v-bind="field"
                                            class="form-control mx-auto"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                        />
                                    </Field>
                                    <ErrorMessage :name="('name_en_' + item.type_id)" class="invalid-feedback"/>
                                </td>
                                <td>
                                    <Field :name="('multiple_' + item.type_id)" v-slot="{ meta, field }" v-model="item.multiple" :rules="multiple_rules">
                                        <input
                                            v-bind="field"
                                            class="form-control mx-auto"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                            type="number"
                                            :min="0"
                                            :max="10"
                                        />
                                    </Field>
                                    <ErrorMessage :name="('multiple_' + item.type_id)" class="invalid-feedback"/>
                                </td>
                                <td>
                                    <Field :name="('order_' + item.type_id)" v-slot="{ meta, field }" v-model="item.order" :rules="order_rules">
                                        <input
                                            v-bind="field"
                                            class="form-control mx-auto"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                            type="number"
                                            :min="1"
                                            :max="99999"
                                        />
                                    </Field>
                                    <ErrorMessage :name="('order_' + item.type_id)" class="invalid-feedback"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 row mx-auto text-center">
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
            </div>
            <div class="mb-3">
            </div>
        </Form>
    </div>
</template>

<script>
import { 
    get_store_type_manage_list,
    save_store_type_manage_list
} from '../../api/store_type';
import { mapActions, mapGetters } from 'vuex';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            store_type_data: [],
            button_disabled: false,
            multiple_rules: yup.number().typeError("必須為數字").min(1, "最小值為1").max(10, "最大值為10").required("點數倍數尚未填寫"),
            order_rules: yup.number().typeError("必須為數字").min(1, "最小值為1").max(99999, "最大值為99999").required("排序尚未填寫"),
            name_en_rules: yup.string().max(50, "店家類型（英文）至多為50個字"),
            can_go_back: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_store_type_manage_list().then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'StoreTypeManagement' });
                } else {
                    this.store_type_data = response.data.response_data;
                }
            })
        },
        save() {
            this.button_disabled = true;
            save_store_type_manage_list({store_type_data: this.store_type_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'StoreTypeManagement' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "StoreTypeManagement"});
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        this.search();
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
};
</script>
